













import {defineComponent, PropType} from '@vue/composition-api';
import {RawTaskComponent} from '@/tasks/types/RawTaskComponent';
import DataDrivenComponent from '@/tasks/components/task-ui-components/DataDrivenComponent.vue';
import {RawTaskState} from '@/task-states/types/RawTaskState';

export default defineComponent({
  name: 'TaskUiComponent',
  components: {DataDrivenComponent},
  inheritAttrs: false,
  props: {
    taskId: {
      type: Number as PropType<number>,
      required: true,
    },
    uiComponentConfig: {
      type: Object as PropType<RawTaskComponent>,
      required: true,
    },
    taskState: {
      type: Object as PropType<RawTaskState | null>,
      default: null,
    },
    allData: {
      // We use null here to bypass Vue's run-time type checks
      type: null as unknown as PropType<Record<string, any> | null>,
      default: null,
    },
  },
});
